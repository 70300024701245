import React, { useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Flex,
  Box,
  useMultiStyleConfig,
  Grid,
} from '@chakra-ui/react';

import Alert from '../components/Alert';
import Persona from '../components/Persona';
import PlayerList from '../components/PlayerList';
import TitleDescription from '../components/TitleDescription';
import LeaveGameButton from '../components/LeaveGameButton';
import Modal from '../components/Modal';

import useFinishRound from '../hooks/useFinishRound';
import { openModal, closedModal } from '../utils/modal';
import useCancelGame from '../hooks/useCancelGame';
import useDestroyPlayer from '../hooks/useDestroyPlayer';

import useGameroomStore from '../stores/useGameroomStore';
import usePlayerStore from '../stores/usePlayerStore';

const Answers = () => {
  const styles = useMultiStyleConfig('GameCard', { variant: 'answer' });

  const [modalInfo, setModalInfo] = useState(closedModal());
  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const setSkipModal = usePlayerStore((state) => state.setSkipModal);
  const skipModal = usePlayerStore((state) => state.skipModal);
  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);

  const { id, username, admin } = playerInfo;

  const {
    id: gameId,
    players,
    length: numberRounds,
    rounds: {
      length: currentRoundNumber,
      [currentRoundNumber - 1]: currentRound,
    },
    pack,
  } = useGameroomStore((state) => state.currentGame);

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const { mutate: finishRound } = useFinishRound();

  const { mutate: cancelGame } = useCancelGame();

  const findPlayerAnswer = (playerId) => {
    return currentRound.answers.find((answer) => answer.playerId === playerId);
  };

  const submitFinishRound = () => {
    finishRound({ gameId, roundId: currentRound.id });
  };

  const revealAnswer = (checkboxChecked) => {
    if (checkboxChecked) setSkipModal(true);
    submitFinishRound();
  };

  const endGame = () => {
    cancelGame({ gameroomKey, gameId });
  };

  const leaveGame = () => {
    destroyPlayer({ gameroomKey, playerId: id });
  };

  const handleRevealAnswer = () => {
    if (skipModal || currentRound.answers.length === players.length) {
      submitFinishRound();
    } else {
      setModalInfo(openModal('reveal_answer', revealAnswer));
    }
  };

  return (
    <Grid templateRows="1fr auto 1fr" sx={styles.grid}>
      <Stack sx={styles.count}>
        <Alert />
        <Text variant="count">
          {currentRoundNumber}/{numberRounds}
        </Text>
      </Stack>
      <Stack sx={styles.card}>
        <Stack gap="4.8rem">
          <Stack gap="2.4rem">
            <TitleDescription
              variant="answers"
              title="Waiting for other players..."
              description={`“${currentRound.quote}“`}
            />
            <Box paddingX="10.8rem">
              <Persona />
            </Box>
          </Stack>
          <PlayerList
            players={players.map((player) => {
              const answer = findPlayerAnswer(player.id);
              let persona = null;
              if (answer) {
                const { personaA, personaB } = pack;
                persona =
                  personaA.id === answer.personaId ? personaA : personaB;
              }

              return {
                username: player.username,
                isAdmin: player.admin,
                isCurrentPlayer: username === player.username,
                leftGameroomAt: player.leftGameroomAt,
                roomProps: {
                  status: persona ? 'answered' : 'unanswered',
                  persona,
                },
                variant: 'answer',
              };
            })}
          />
        </Stack>
        {admin && (
          <Stack align="center" gap="0.8rem">
            <Button
              variant="solid"
              scheme="main.purple"
              onClick={handleRevealAnswer}
            >
              reveal answer
            </Button>
            <Button
              variant="outline"
              scheme="secondary.pink"
              onClick={() => setModalInfo(openModal('end_game', endGame))}
            >
              end game
            </Button>
          </Stack>
        )}
      </Stack>
      {!admin && (
        <Flex sx={styles.button}>
          <LeaveGameButton
            onClick={() => setModalInfo(openModal('leave_game', leaveGame))}
          />
        </Flex>
      )}
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Grid>
  );
};

export default Answers;
