import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Grid,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import Alert from '../components/Alert';
import TitleDescription from '../components/TitleDescription';
import Persona from '../components/Persona';
import LeaveGameButton from '../components/LeaveGameButton';
import PlayerList from '../components/PlayerList';
import Modal from '../components/Modal';

import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useCancelGame from '../hooks/useCancelGame';
import useBuildNextRound from '../hooks/useBuildNextRound';
import useFinishGame from '../hooks/useFinishGame';

import useGameroomStore from '../stores/useGameroomStore';
import usePlayerStore from '../stores/usePlayerStore';

import { openModal, closedModal } from '../utils/modal';

const Result = () => {
  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);
  const randomCopies = useGameroomStore((state) => state.randomCopies);
  const [currentRandomCopy, setCurrentRandomCopy] = useState(null);
  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const { admin } = playerInfo;

  const styles = useMultiStyleConfig('GameCard');

  const [modalInfo, setModalInfo] = useState(closedModal());

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const { mutate: cancelGame } = useCancelGame();

  const {
    id: gameId,
    players,
    length: numberRounds,
    rounds: {
      length: currentRoundNumber,
      [currentRoundNumber - 1]: { rightAnswer, ...currentRound },
    },
  } = useGameroomStore((state) => state.currentGame);

  const { mutate: buildNextRound } = useBuildNextRound();

  const { mutate: finishGame } = useFinishGame();

  const handleLeave = () => {
    if (playerInfo.admin) {
      cancelGame({ gameroomKey, gameId });
    } else {
      destroyPlayer({ gameroomKey, playerId: playerInfo.id });
    }
  };

  const getPlayerAnswer = (playerId) => {
    return currentRound.answers.find((answer) => answer.playerId === playerId);
  };

  const isAnswerCorrect = useCallback(
    (playerAnswer) => {
      return playerAnswer && rightAnswer.id === playerAnswer.personaId;
    },
    [rightAnswer.id]
  );

  const isCurrentPlayerCorrect = isAnswerCorrect(
    getPlayerAnswer(playerInfo.id)
  );

  const getRandomCopy = (copies) => {
    const randomIndex = Math.floor(Math.random() * copies.length);
    return copies[randomIndex];
  };

  const handleNextQuote = () => {
    if (!admin) return;

    if (currentRoundNumber < numberRounds) {
      buildNextRound({ gameId });
    } else {
      finishGame({
        gameroomKey,
        gameId,
      });
    }
  };

  useEffect(() => {
    if (currentRandomCopy === null) {
      const copy = isCurrentPlayerCorrect
        ? getRandomCopy(randomCopies.rightAnswer)
        : getRandomCopy(randomCopies.wrongAnswer);
      setCurrentRandomCopy(copy);
    }
  }, [currentRandomCopy, randomCopies, isCurrentPlayerCorrect]);

  return (
    <Grid templateRows="1fr auto 1fr" sx={styles.grid}>
      <Stack sx={styles.count}>
        <Alert />
        <Text variant="count">
          {currentRoundNumber}/{numberRounds}
        </Text>
      </Stack>
      <Stack sx={styles.card}>
        <Stack gap="4.8rem" align="center">
          <Stack gap="2.4rem" align="center">
            <TitleDescription
              title={currentRandomCopy}
              description={`“${currentRound.quote}“`}
              variant="answer"
              isCorrect={isCurrentPlayerCorrect}
            />
            <Box paddingX="10.8rem" width="100%">
              <Persona
                name={rightAnswer.name}
                avatar={rightAnswer.avatar}
                color={rightAnswer.color}
                secondaryColor={rightAnswer.secondaryColor}
              />
            </Box>
          </Stack>
          <PlayerList
            playerItemVariant="answer"
            players={players.map((player) => {
              const isPlayerCorrect = isAnswerCorrect(
                getPlayerAnswer(player.id)
              );
              return {
                username: player.username,
                isAdmin: player.admin,
                isCurrentPlayer: player.username === playerInfo.username,
                leftGameroomAt: player.leftGameroomAt,
                roomProps: {
                  status: isPlayerCorrect ? 'correct' : 'incorrect',
                },
                variant: 'answer',
              };
            })}
          />
        </Stack>
        {admin && (
          <Stack minWidth="35.5rem" gap="0.8rem" align="center">
            <Button
              variant="solid"
              scheme="main.purple"
              onClick={handleNextQuote}
            >
              {currentRoundNumber === numberRounds
                ? 'show game scores'
                : 'next quote'}
            </Button>
            <Button
              variant="outline"
              scheme="secondary.pink"
              onClick={() => setModalInfo(openModal('end_game', handleLeave))}
            >
              end game
            </Button>
          </Stack>
        )}
      </Stack>
      {!admin && (
        <Flex sx={styles.button}>
          <LeaveGameButton
            onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            isAdmin={playerInfo.admin}
          />
        </Flex>
      )}
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Grid>
  );
};

export default Result;
