const createGameroomSlice = (set) => ({
  gameroom: null,
  gameroomFeedBackMessage: null,
  alertMessage: null,

  setGameroomKey: (gameroomKey) =>
    set(
      (state) => {
        return {
          ...state,
          gameroom: {
            gameroomKey,
          },
        };
      },
      undefined,
      'gameroom/setGameroomKey'
    ),

  updateGameroom: (gameroom) =>
    set(
      (state) => {
        return {
          ...state,
          gameroom: {
            gameroomKey: gameroom.gameroomKey,
            adminId: gameroom.adminId,
          },
          currentGame: gameroom.currentGame,
          players: gameroom.players,
        };
      },
      undefined,
      'gameroom/updateGameroom'
    ),

  setGameroomFeedBackMessage: (gameroomFeedBackMessage) =>
    set(
      (state) => {
        return {
          ...state,
          gameroomFeedBackMessage,
        };
      },
      undefined,
      'gameroom/setGameroomFeedBackMessage'
    ),

  setAlertMessage: (alertMessage) =>
    set({ alertMessage }, undefined, 'gameroom/setAlertMessage'),

  clearAlertMessage: () =>
    set({ alertMessage: null }, undefined, 'gameroom/clearAlertMessage'),

  reset: () =>
    set(
      () => ({
        gameroom: null,
        gameroomFeedBackMessage: null,
      }),
      undefined,
      'gameroom/reset'
    ),
});

export default createGameroomSlice;
