import React, { useState } from 'react';
import { Center, Stack, Button, Box, Text, Flex } from '@chakra-ui/react';

import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';

import useNavigationStore from '../stores/useNavigationStore';
import useGameroomStore from '../stores/useGameroomStore';

import paths from '../constants/paths';

import Alert from '../components/Alert';
import Modal from '../components/Modal';
import PlayerList from '../components/PlayerList';
import TitleDescription from '../components/TitleDescription';
import RoomCode from '../components/RoomCode';

import { openModal, closedModal } from '../utils/modal';
import usePlayerStore from '../stores/usePlayerStore';

const AwaitingPlayers = () => {
  const navigateTo = useNavigationStore((state) => state.navigateTo);

  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const { id: playerId, admin } = playerInfo || {};

  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);
  const gameroomFeedBackMessage = useGameroomStore(
    (state) => state.gameroomFeedBackMessage
  );

  const [modalInfo, setModalInfo] = useState(closedModal());
  const players = useGameroomStore(
    (state) =>
      state.players.map((player) => ({
        ...player,
        isAdmin: player.admin,
        isCurrentPlayer: player.id === playerId,
      })) ?? []
  );

  const maxPlayers = 50;

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const handleLeave = () => {
    if (admin) {
      destroyGameroom({ gameroomKey });
    } else {
      destroyPlayer({ gameroomKey, playerId });
    }
  };

  const handleStartGame = () => {
    navigateTo(paths.packs);
  };

  return (
    <Center minHeight="100vh" minWidth="fit-content">
      <Stack position="relative">
        <Alert />
        <Flex align="center" direction="column" gap="2.4rem">
          <Stack
            bg="main.purple.darker"
            paddingY="2.4rem"
            paddingX="3.2rem"
            gap="1rem"
            borderRadius="2rem"
            height={admin ? '52.572rem' : '48.572rem'}
          >
            <Stack
              width="27.9rem"
              height="47.972rem"
              gap="3.2rem"
              alignItems="center"
            >
              <Box position="relative">
                <TitleDescription
                  title="Players"
                  description="After you start the game, no more players will be able to join."
                />
                <Text variant="playerCount">
                  ({players.length}/{maxPlayers})
                </Text>
              </Box>
              <RoomCode code={gameroomKey} copyable />
              <PlayerList players={players} />
            </Stack>

            <Stack width="27.9rem" height="9rem" gap="0.8rem" justify="end">
              {admin && (
                <Button
                  variant="solid"
                  scheme="main.purple"
                  onClick={handleStartGame}
                >
                  {'start game'}
                </Button>
              )}
              <Button
                variant="outline"
                scheme="secondary.pink"
                onClick={() =>
                  setModalInfo(openModal('leave_game', handleLeave))
                }
              >
                {admin ? 'cancel game' : 'leave game'}
              </Button>
            </Stack>
          </Stack>
          {!admin && (
            <Text color="white" variant="footer">
              {gameroomFeedBackMessage ||
                'Waiting for other players to join...'}
            </Text>
          )}
        </Flex>
      </Stack>

      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default AwaitingPlayers;
