const config = {
  development: {
    API_WEBSOCKET_URL: 'ws://localhost:3334/cable',
    API_BASE_URL: 'http://localhost:3000/api/v2/',
    ASSETS_URL: 'http://localhost:5173/assets',
  },
  staging: {
    API_WEBSOCKET_URL: 'wss://ws.gus-staging.dmz-cloud.com/cable',
    API_BASE_URL: 'https://gus-staging.dmz-cloud.com/api/v2/',
    ASSETS_URL: 'https://gus-web-staging.dmz-cloud.com/assets',
  },
  production: {
    API_WEBSOCKET_URL: 'wss://ws.goozebooze.com/cable',
    API_BASE_URL: 'https://goozebooze.com/api/v2/',
    ASSETS_URL: 'https://gus-web-staging.dmz-cloud.com/assets', // TODO: change this to the prod web url
  },
};

export default config;
