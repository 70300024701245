import React, { Fragment, useMemo } from 'react';
import { Stack, useMultiStyleConfig, Image } from '@chakra-ui/react';

import DottedBorder from '../assets/dotted-border.svg';

import PlayerItem from './PlayerItem';

import { PlayerListShape } from '@utils/propsShape';

const PlayerList = ({ players, variant }) => {
  const style = useMultiStyleConfig('PlayerList', { variant });

  const sortedPlayers = useMemo(() => {
    return players.sort((a, b) => {
      if (variant === 'leaderBoard') {
        // in this case the players come already sorted by scores, only missing sorting by name
        if (a.roomProps.value === b.roomProps.value)
          return a.username.localeCompare(b.username);

        if (a.roomProps.value === '-') return 1;
        if (b.roomProps.value === '-') return -1;

        return b.roomProps.value - a.roomProps.value;
      }

      if (a.isAdmin) return -1;
      if (b.isAdmin) return 1;

      if (a.isCurrentPlayer) return -1;
      if (b.isCurrentPlayer) return 1;

      return a.username.localeCompare(b.username);
    });
  }, [players, variant]);

  return (
    <Stack {...style.container}>
      {sortedPlayers.map((player, idx) => {
        return (
          <Fragment key={idx}>
            <PlayerItem {...player} />
            {idx !== players.length - 1 && (
              <Image src={DottedBorder} alt="border" minH="0.25rem" />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

PlayerList.propTypes = PlayerListShape;

export default PlayerList;
