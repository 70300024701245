import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'grid',
  'count',
  'card',
  'button',
]);

const GameCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    grid: {
      minHeight: '100vh',
      minWidth: 'fit-content',
      alignItems: 'center',
      justifyItems: 'center',
      gap: '2rem',
    },
    count: {
      position: 'relative',
      alignSelf: 'end',
      justifyContent: 'flex-end',
      maxWidth: '41.9rem',
      width: '100%',
    },
    card: {
      bg: 'main.purple.darker',
      paddingX: '3.2rem',
      paddingY: '2.4rem',
      borderRadius: '2rem',
      maxWidth: '41.9rem',
      gap: '3.2rem',
    },
    button: {
      width: '100vw',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'end',
    },
  },
  variants: {
    round: {
      card: {
        gap: '3.2rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '70rem',
      },
    },
  },
});

export default GameCard;
