import { v4 as uuid } from 'uuid';

import { browserName, browserVersion } from 'react-device-detect';

import { getApiClient } from '..';

const createDevice = async (currentDeviceUuid) => {
  const deviceUuid = currentDeviceUuid || uuid();

  const appVersion = APP_VERSION;
  const osVersion = `${browserName}-${browserVersion}`;
  const response = await getApiClient().post('/devices', {
    deviceUuid,
    os: 'web',
    appVersion,
    osVersion,
  });
  return response.data;
};

export default createDevice;
