import React, { useState, useEffect } from 'react';
import {
  Stack,
  Button,
  Box,
  Text,
  Center,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';

import useGetPacks from '../hooks/useGetPacks';
import useCreateGame from '../hooks/useCreateGame';
import useDestroyGameroom from '../hooks/useDestroyGameroom';

import useNavigationStore from '../stores/useNavigationStore';
import usePlayerStore from '../stores/usePlayerStore';
import useGameroomStore from '../stores/useGameroomStore';

import Alert from '../components/Alert';
import Loading from '../components/Loading';
import PackSlider from '../components/PackSlider';
import Modal from '../components/Modal';

import paths from '../constants/paths';

const PackSelection = () => {
  const navigateTo = useNavigationStore((state) => state.navigateTo);
  const [selectedPack, setSelectedPack] = useState(null);
  const { data: packs, isLoading } = useGetPacks();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const playerInfo = usePlayerStore((state) => state.playerInfo);
  const gameroomKey = useGameroomStore((state) => state.gameroom.gameroomKey);

  useEffect(() => {
    if (!playerInfo) {
      navigateTo(paths.root);
      return;
    }

    if (!playerInfo.admin) {
      navigateTo(paths.awaiting);
    }
  }, [navigateTo]);

  const handlePackClick = (position) => {
    setSelectedPack((prevSelectedPackId) =>
      prevSelectedPackId === position ? null : position
    );
  };

  const { mutate: createGame } = useCreateGame();

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const handleSubmit = async () => {
    if (selectedPack !== null) {
      createGame(packs[selectedPack].id);
    }
  };

  const handleCancelGame = () => {
    destroyGameroom({ gameroomKey });
  };

  return (
    <Flex
      width="100vw"
      minW="42rem"
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      padding="0.5rem"
    >
      {isLoading ? (
        <Loading title="Loading..." />
      ) : (
        <Stack gap="3.2rem" maxW="42rem" justifyContent="center">
          <Stack position="relative">
            <Alert />
            <Text variant="pageTitle">Packs</Text>
          </Stack>
          {packs?.length > 0 ? (
            <Box>
              <PackSlider
                packs={packs}
                selectedPack={selectedPack}
                onClick={handlePackClick}
              />
            </Box>
          ) : (
            <Center color="white" fontSize="1.8rem" height="75%">
              No available packs.
            </Center>
          )}
          <Stack gap="0.8rem" alignItems="center">
            <Button
              variant="solid"
              scheme="main.purple"
              onClick={handleSubmit}
              isDisabled={selectedPack === null}
            >
              Play
            </Button>
            <Button variant="outline" scheme="main.purple" onClick={onOpen}>
              End game
            </Button>
          </Stack>
        </Stack>
      )}
      <Modal
        title="Cancel game?"
        description="Doing this will close the current game room."
        confirmText="Ok"
        onConfirm={handleCancelGame}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default PackSelection;
