import React, { useState } from 'react';
import { Box, Button, VStack, Fade } from '@chakra-ui/react';

import Alert from '../components/Alert';
import WavesAnimation from '../components/animations/WavesAnimation';
import LogoAnimation from '../components/animations/LogoAnimation';

import useNavigationStore from '../stores/useNavigationStore';

import paths from '../constants/paths';

const Home = () => {
  const [isClickable, setIsClickable] = useState(false);
  const navigateTo = useNavigationStore((state) => state.navigateTo);

  return (
    <>
      <Alert isHomeScreen />
      <Box position="absolute" bg="main.purple.darkest" h="100%" w="100%" />
      <Box minH="100vh" position="relative">
        <WavesAnimation />
        <Box
          position="relative"
          width="fit-content"
          margin="auto"
          paddingX="2rem"
          minW="42rem"
        >
          <LogoAnimation />
          <Fade
            in={true}
            transition={{ enter: { duration: 1, delay: 2.65 } }}
            onAnimationComplete={() => setIsClickable(true)}
          >
            <VStack
              gap="0.8rem"
              position="absolute"
              bottom="0"
              left="50%"
              transform="translateX(-50%)"
              width="100%"
              pointerEvents={isClickable ? 'auto' : 'none'}
            >
              <Button
                scheme="main.purple"
                onClick={() => navigateTo(paths.joinGameroom)}
              >
                Play
              </Button>
              <Button
                variant="outline"
                scheme="main.purple"
                onClick={() => navigateTo(paths.createGameroom)}
              >
                Create room
              </Button>
            </VStack>
          </Fade>
        </Box>
      </Box>
    </>
  );
};

export default Home;
