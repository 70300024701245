import React from 'react';
import ReactDOM from 'react-dom/client';

import { ChakraProvider } from '@chakra-ui/react';

import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import App from './bundles/App';

import theme from './theme';

import { configureApiClient } from './api';

import config from './utils/config';

configureApiClient({
  baseURL: config[import.meta.env.MODE].API_BASE_URL,
  headers: {
    Authorization: `Token ${import.meta.env.VITE_API_DEVICE_KEY}`,
  },
});

const honeybadgerConfig = {
  apiKey: import.meta.env.VITE_HONEYBADGER_KEY,
  environment: import.meta.env.MODE,
  revision: import.meta.env.HEROKU_SLUG_COMMIT,
};

const honeybadger = Honeybadger.configure(honeybadgerConfig);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <HoneybadgerErrorBoundary honeybadger={honeybadger}>
        <App />
      </HoneybadgerErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>
);
