import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Alert as ChakraAlert,
  AlertDescription,
  Fade,
} from '@chakra-ui/react';

import useGameroomStore from '../stores/useGameroomStore';

const Alert = ({ isHomeScreen }) => {
  const alertMessage = useGameroomStore((state) => state.alertMessage);
  const clearAlertMessage = useGameroomStore(
    (state) => state.clearAlertMessage
  );

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        clearAlertMessage();
      }, 5000);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [alertMessage, clearAlertMessage]);

  return (
    <Flex
      justifyContent="center"
      position="absolute"
      top={!isHomeScreen ? '-2.4rem' : '3.2rem'}
      transform={!isHomeScreen && 'translateY(-100%)'}
      paddingX="2.4rem"
      width="100%"
    >
      <Fade in={!!alertMessage}>
        {alertMessage && (
          <ChakraAlert>
            <AlertDescription>{alertMessage}</AlertDescription>
          </ChakraAlert>
        )}
      </Fade>
    </Flex>
  );
};

Alert.propTypes = {
  isHomeScreen: PropTypes.bool,
};

export default Alert;
