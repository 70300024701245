const Alert = {
  baseStyle: {
    container: {
      borderRadius: '2rem',
      paddingX: '1.6rem',
      paddingY: '0.8rem',
      width: 'fit-content',
      bgColor: 'white',
      zIndex: 10,
    },
    description: {
      textColor: 'main.purple.darkest',
    },
  },
  sizes: {
    xl: {
      container: {
        maxW: '41.9rem',
      },
      description: {
        fontSize: '1.4rem',
      },
    },
  },
  defaultProps: {
    size: 'xl',
  },
};

export default Alert;
