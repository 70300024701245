import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text, Image, useMultiStyleConfig } from '@chakra-ui/react';

import copy from '../assets/copy.svg';

import useGameroomStore from '../stores/useGameroomStore';

const RoomCode = ({ code, copyable = false }) => {
  const setAlertMessage = useGameroomStore((state) => state.setAlertMessage);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(code);
    setAlertMessage('Room code copied');
  };

  const style = useMultiStyleConfig('RoomCode', {
    copyable,
  });

  return (
    <HStack {...style.container}>
      <Text {...style.roomCodeText}>
        Room code:{' '}
        <Text as="span" {...style.code}>
          {code}
        </Text>
      </Text>
      {copyable && (
        <Image
          onClick={copyToClipboard}
          src={copy}
          alt="copy-button"
          {...style.copy}
        />
      )}
    </HStack>
  );
};

RoomCode.propTypes = {
  code: PropTypes.string.isRequired,
  copyable: PropTypes.bool,
};

export default RoomCode;
